/* ======================= */
/* Layout Geral e Fontes   */
/* ======================= */

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  color: #333;
  background-color: #f5f7fa;
  line-height: 1.6;
}

.resultadosContainer {
  padding: 2rem;
  min-height: 100vh;
}

.resultadosContainer table tr:nth-child(even) {
  background-color: transparent; /* Define a cor desejada ou remova o fundo */
}

.resultadosContainer table th:first-child,
.resultadosContainer table td:first-child {
  width: 50px; 
}

/* ======================= */
/* Títulos e Cabeçalhos    */
/* ======================= */

.resultadosContainer h1{
  text-align: center;
  margin-bottom: 1rem;
  font-weight: 700;
  color: #e1f3ff;
}
.resultadosContainer h2 {
  text-align: center;
  margin-bottom: 2rem;
  font-weight: 600;
  color: #222;
}

.titulo h1 {
  font-size: 2.5rem;
}

.titulo h2 {
  font-size: 2rem;
}

/* ======================= */
/* Containers Principais   */
/* ======================= */

.provaContainer,
.absolutoContainer {
  background-color: #fff;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.08);
  transition: transform 0.3s ease;
}

.provaContainer:hover,
.absolutoContainer:hover {
  transform: translateY(-3px);
}

.provaContainer h2,
.absolutoContainer h2 {
  margin-bottom: 1rem;
  border-bottom: 2px solid #3498db;
  padding-bottom: 0.5rem;
  color: #3498db;
  font-size: 1.5rem;
}

/* ======================= */
/* Componentes Específicos */
/* ======================= */

.bateriaContainer {
  background-color: #f9fbfc;
  border-left: 4px solid #3498db;
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.bateriaContainer h3 {
  margin-bottom: 0.75rem;
  font-size: 1.25rem;
  color: #2c3e50;
}

.nadador {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0.5rem 0;
  border-bottom: 1px dashed #ddd;
}

.nadador:last-child {
  border-bottom: none;
}

.nadador span {
  font-size: 0.95rem;
  margin-right: 1rem;
}

/* ======================= */
/* Tabelas Personalizadas  */
/* ======================= */

.tabelaPersonalizada table,
.tabelaClassificacaoFinal table,
.tabelaResultadosBalizamento table,
.tabelaClassificacaoCategoria table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  text-align: center;
}

.tabelaPersonalizada thead {
  background-color: #e9eef4;
  color: #222;
  font-weight: 600;
  font-size: 1.1rem;
  border-bottom: 2px solid #3498db;
}

.tabelaPersonalizada thead th,
.tabelaPersonalizada tbody td {
  padding: 0.75rem 1rem;
  text-align: center;
}

.tabelaPersonalizada tbody tr {
  transition: background-color 0.2s ease;
}

.tabelaPersonalizada tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.tabelaResultadosBalizamento table th:first-child,
.tabelaResultadosBalizamento table td:first-child {
  width: 40px; /* Ajuste para a coluna "Raia" na primeira aba */
}

.tabelaClassificacaoCategoria table th:first-child,
.tabelaClassificacaoCategoria table td:first-child {
  width: 80px; /* Ajuste para a coluna "Classificação" na segunda aba */
}

/* ======================= */
/* Tabela Absoluto         */
/* ======================= */

.absolutoTabela {
  width: 100%;
  text-align: center;
}

.absolutoTabela th,
.absolutoTabela td {
  padding: 0.75rem;
  text-align: center;
}

/* ======================= */
/* Outros Componentes      */
/* ======================= */

.classificacaoTabela {
  text-align: center;
}

.classificacaoTabela th,
.classificacaoTabela td {
  padding: 0.75rem;
  text-align: center;
}

.cardContainer {
  cursor: pointer;
}

.cardContainer h3 {
  color: #000;
}

/* ======================= */
/* Mensagens               */
/* ======================= */

.loadingMessage {
  font-size: 2rem;
  color: #333;
  text-align: center;
  margin-top: 2rem;
}

/* ======================= */
/* Responsividade          */
/* ======================= */

@media (max-width: 768px) {
  .nadador {
    flex-direction: column;
  }

  .tabelaPersonalizada table,
  .tabelaClassificacaoFinal table {
    font-size: 0.9rem;
  }

  .resultadosContainer {
    padding: 1rem;
  }
}

/* ======================= */
/* Abas                   */
/* ======================= */

.abasContainer .aba {
  color: #3498db; /* Azul padrão para abas antes de clicar */
}
